/* global gtag */

const toggleClass = (el, state, classname) => {
  el.classList[state ? 'add' : 'remove'](classname);
};

const getBrowserHeight = () => window.innerHeight;

const isMobileSafari = () => navigator.userAgent.indexOf('Version') > -1 && /iPad|iPhone|iPod/.test(navigator.userAgent);

const rootElement = () => document.scrollingElement || document.documentElement || document.body;

const onSendGTag = (action, params) => {
  if (typeof gtag === 'undefined') return;

  window.dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: 'sendGTag',
    eventAction: action,
    eventParams: params,
  });

  gtag('event', action, params);
};

const dateFormat = (datetime) => {
  if (!datetime) {
    return "Invalid Date";
  }

  const date = new Date(datetime);
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  const tokyoDate = new Date(
    date.toLocaleString("en-US", { timeZone: "Asia/Tokyo" }),
  );

  const year = tokyoDate.getFullYear();
  const month = tokyoDate.getMonth() + 1;
  const day = tokyoDate.getDate();

  return `${year}.${month}.${day}`;
};

export {
  toggleClass, getBrowserHeight, isMobileSafari, rootElement, onSendGTag, dateFormat,
};
