/* global Swiper */
import Abstract from '../abstract';
import { onSendGTag } from '../module';

const JP_TEXT = {
  swipe: 'スワイプ',
  navigation: 'ナビゲーション',
  list: 'リスト',
  next: '次',
  prev: '前',
  tap: 'タップ',
};

const swiperInit = (parentEl, options) => {
  const container = parentEl.querySelector('.swiper-container');

  return new Swiper(container, options);
};

const labelForChangePhotoGTag = (type, direction) => {
  const typeLabel = JP_TEXT[type] || type;
  const directionLabel = JP_TEXT[direction] || direction;

  return `${typeLabel}_${directionLabel}`;
};

const onSendClickBackToArticleButtonLinkGTag = (event) => {
  onSendGTag('タップ', {
    event_category: '画像ページ記事に戻る',
    event_label: 'タップ',
    link_url: event.currentTarget.getAttribute('href'),
  });
};

const onSendClickArticleRankingLinkGTag = (event, index) => {
  onSendGTag('タップ', {
    event_category: '画像ページランキング',
    event_label: index,
    link_url: event.currentTarget.getAttribute('href'),
  });
};

const onSendChangePhotoGTag = (type, direction) => {
  if (!type || !direction) {
    return;
  }

  onSendGTag('タップ', {
    event_category: '画像ページ_画像スライド',
    event_label: labelForChangePhotoGTag(type, direction),
  });
};

export default class ArticlePhotoView extends Abstract {
  init() {
    this.getElement();

    this.initSwiper();

    this.addEvent();
  }

  getElement() {
    this.currentImagePerNumberOfPhotos = document.querySelector(
      '.js-article-photo-current-image-per-number-of-images',
    );
    this.articlePhotoCarouselContainer = document.querySelector('.js-article-photo-image-carousel-container');
    this.articlePhotoCarouselNavigationPreviousButton = document.querySelector(
      '.js-article-photo-image-carousel-navigation-prev',
    );
    this.articlePhotoCarouselNavigationNextButton = document.querySelector(
      '.js-article-photo-image-carousel-navigation-next',
    );
    this.articlePhotoBackButtonLinks = document.querySelectorAll('.js-article-photo-image-container-back-button');
    this.articlePhotos = document.querySelector('.js-article-photo-images-list');
    this.articlePhotoRankingLinks = document.querySelectorAll('.js-article-photo-ranking-link');
  }

  updateCurrentImagePerNumberOfPhotosContent(currentIndex, totalItems) {
    if (!this.currentImagePerNumberOfPhotos) {
      return;
    }

    this.currentImagePerNumberOfPhotos.innerHTML = `${currentIndex}/${totalItems}`;
  }

  updateArticlePhotoItemStyle(currentArticlePhotoId) {
    if (!this.articlePhotos) {
      return;
    }

    this.articlePhotos.querySelectorAll('.js-article-photo-images-item').forEach((imageElement) => {
      if (imageElement.dataset.id === currentArticlePhotoId) {
        imageElement.classList.add('article-photo-Images_Item-Current');
        return;
      }

      imageElement.classList.remove('article-photo-Images_Item-Current');
    });
  }

  initSwiper() {
    if (!this.articlePhotoCarouselContainer) {
      return;
    }

    let currentArticlePhotoId = this.articlePhotoCarouselContainer.dataset.currentImageId;
    const articlePhotoCarousels = this.articlePhotoCarouselContainer.querySelectorAll(
      '.js-article-photo-image-carousel-slide',
    );
    let currentArticlePhotoCarouselIndex = 0;

    articlePhotoCarousels.forEach((imageElement, imageElementIndex) => {
      if (imageElement.dataset.id === currentArticlePhotoId) {
        currentArticlePhotoCarouselIndex = imageElementIndex;
      }
    });

    this.articlePhotoCarouselContainerSwiper = swiperInit(this.articlePhotoCarouselContainer, {
      slidesPerView: 'auto',
      loop: true,
      centeredSlides: true,
      initialSlide: currentArticlePhotoCarouselIndex,
      navigation: {
        prevEl: '.js-article-photo-image-carousel-navigation-prev',
        nextEl: '.js-article-photo-image-carousel-navigation-next',
      },
      on: {
        init: () => {
          this.articlePhotoCarouselContainer.classList.add('article-photo-ImageCarousel_Container-visible');
          this.updateCurrentImagePerNumberOfPhotosContent(
            currentArticlePhotoCarouselIndex + 1,
            articlePhotoCarousels.length,
          );
          this.updateArticlePhotoItemStyle(currentArticlePhotoId);
        },
        slideChange: () => {
          if (!this.articlePhotoCarouselContainerSwiper) {
            return;
          }

          const {
            loopedSlides, realIndex, slides, swipeDirection,
          } = this.articlePhotoCarouselContainerSwiper;
          const currentArticlePhotoElement = slides[loopedSlides + realIndex];

          currentArticlePhotoId = currentArticlePhotoElement.dataset.id;
          this.updateCurrentImagePerNumberOfPhotosContent(realIndex + 1, loopedSlides);
          this.updateArticlePhotoItemStyle(currentArticlePhotoId);

          if (swipeDirection) {
            onSendChangePhotoGTag('swipe', swipeDirection);
          }

          const { pathname } = window.location;
          const [articleId, previousPhotoId] = pathname.match(/(\d+)/gm);
          if (currentArticlePhotoId !== previousPhotoId) {
            const currentPathname = pathname.replace(`photos/${previousPhotoId}`, `photos/${currentArticlePhotoId}`);

            window.history.pushState({
              articleId,
              previousPhotoId,
            }, '', currentPathname);
          }
        },
      },
    });
  }

  updateCurrentArticlePhotoCarouse(currentArticlePhotoId) {
    if (!this.articlePhotoCarouselContainerSwiper) {
      return;
    }

    const { loopedSlides, slides } = this.articlePhotoCarouselContainerSwiper;
    Object.values(slides).forEach((slide, slideIndex) => {
      if (
        slideIndex >= loopedSlides && slideIndex < loopedSlides * 2
        && slide?.dataset?.id === currentArticlePhotoId
      ) {
        this.articlePhotoCarouselContainerSwiper.slideTo(slideIndex);
      }
    });
  }

  addEvent() {
    if (this.articlePhotoCarouselNavigationPreviousButton) {
      this.articlePhotoCarouselNavigationPreviousButton.addEventListener('click', () => {
        onSendChangePhotoGTag('navigation', 'prev');
      });
    }

    if (this.articlePhotoCarouselNavigationNextButton) {
      this.articlePhotoCarouselNavigationNextButton.addEventListener('click', () => {
        onSendChangePhotoGTag('navigation', 'next');
      });
    }

    if (this.articlePhotos) {
      this.articlePhotos.querySelectorAll('.js-article-photo-images-item').forEach((imageElement) => {
        imageElement.addEventListener('click', (event) => {
          const currentArticlePhotoId = event.currentTarget.dataset.id;

          onSendChangePhotoGTag('list', 'tap');
          this.updateCurrentArticlePhotoCarouse(currentArticlePhotoId);
        });
      });
    }

    if (this.articlePhotoBackButtonLinks) {
      this.articlePhotoBackButtonLinks.forEach((element) => {
        element.addEventListener('click', onSendClickBackToArticleButtonLinkGTag);
      });
    }

    this.articlePhotoRankingLinks.forEach((element, index) => {
      element.addEventListener('click', (event) => onSendClickArticleRankingLinkGTag(event, index + 1));
    });

    window.addEventListener('popstate', (event) => {
      const { pathname } = event.currentTarget.location;
      const [, photoId] = pathname.match(/(\d+)/gm);

      this.updateCurrentArticlePhotoCarouse(photoId);
    });
  }
}
